import {
  ChatReady,
  ChatState,
  DialogReady,
  DialogState,
  InteractionMachine,
  OfferReady,
  OfferState,
  PopupReady,
  PopupState,
  PullupReady,
  PullupState,
} from "./index";

import BaseLogger from "../../../../../service/logger/base/base_logger";
import BaseStorageRegistrar from "../../../service/storage/base/base_storage_registrar";
import { ChatOptions } from "./states/chat/typedef";
import ChatStateError from "./states/chat/chat_state_error";
import ChatWidget from "./states/chat/widget/chat_widget";
import ChatWidgetComposer from "./states/chat/widget/chat_widget_composer";
import Factory from "../../../../../service/factory";
import InteractionManager from "../interaction_manager";
import InteractionState from "./states/interaction_state";
import InteractionStateFactory from "./states/interaction_state_factory";
import Logger from "../../../../../service/logger/logger";
import Offer from "../../../model/offer";
import OfferEngagement from "../../../model/offer_engagement";
import StorageRegistrar from "../../../service/storage/storage_registrar";
import { isUndefined } from "../../../../../service/lang";
import State from "../../../state/state";
import StateBuilder from "../../../state/state_builder";
import BaseStateBuilder from "../../../state/base/base_state_builder";
import { iOfferState } from "./states/iOffer/iOffer_state";
import { BannerReady } from "./states/iOffer/banner_ready";

export abstract class BaseInteractionMachine implements InteractionMachine {
  private _chat: ChatState | undefined;
  private _chatEngagement: ChatOptions | undefined;
  private _chatWidget: ChatWidget | undefined;
  private _chatWidgetComposer: ChatWidgetComposer | undefined;
  private _context: InteractionManager | undefined;
  private _dialog: DialogState | undefined;
  private _logger: Logger | undefined;
  private _offer: OfferState | undefined;
  private _offerEngagement: OfferEngagement | undefined;
  private _popup: PopupState | undefined;
  protected _pullup: PullupState | undefined;
  protected _iOfferBanner: iOfferState | undefined;
  private _storage: StorageRegistrar | undefined;
  private _stateBuilder: StateBuilder | undefined;

  constructor(manager?: InteractionManager) {
    if (!isUndefined(manager)) {
      this._context = manager;
    }
  }

  protected get state(): State {
    return this.stateBuilder.snapshot;
  }

  protected get stateBuilder(): StateBuilder {
    if (isUndefined(this._stateBuilder)) {
      this._stateBuilder = Factory.instance.build(BaseStateBuilder);
    }

    return this._stateBuilder;
  }

  get chat(): ChatState {
    if (isUndefined(this._chat)) {
      this._chat = InteractionStateFactory.build(
        ChatReady,
        this.context,
        this
      ) as ChatState;
    }

    return this._chat;
  }

  get chatOptions(): ChatOptions | undefined {
    return this._chatEngagement;
  }

  get chatWidget(): ChatWidget {
    if (isUndefined(this._chatWidget)) {
      if (isUndefined(this.chatOptions)) {
        throw ChatStateError.undefinedEngagement();
      } else {
        this._chatWidget = this.chatWidgetComposer.compose(this.chatOptions);
      }
    }

    return this._chatWidget;
  }

  protected get chatWidgetComposer(): ChatWidgetComposer {
    if (isUndefined(this._chatWidgetComposer)) {
      this._chatWidgetComposer = Factory.instance.build(ChatWidgetComposer);
    }

    return this._chatWidgetComposer;
  }

  get context(): InteractionManager {
    if (isUndefined(this._context)) {
      throw new Error("Context should be set!");
    }

    return this._context;
  }

  get dialog(): DialogState {
    if (isUndefined(this._dialog)) {
      this._dialog = InteractionStateFactory.build(
        DialogReady,
        this.context,
        this
      ) as DialogState;
    }

    return this._dialog;
  }

  get hasChatEngagement(): boolean {
    return !isUndefined(this.chatOptions);
  }

  get hasOfferEngagement(): boolean {
    return !isUndefined(this.offerEngagement);
  }

  protected get isInteractionBlocked(): boolean {
    return this.storage.getBlockedInteraction();
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  get offer(): OfferState {
    if (isUndefined(this._offer)) {
      this._offer = InteractionStateFactory.build(
        OfferReady,
        this.context,
        this
      ) as OfferState;
    }

    return this._offer;
  }

  get offerEngagement(): OfferEngagement | undefined {
    return this._offerEngagement;
  }

  get popup(): PopupState {
    if (isUndefined(this._popup)) {
      this._popup = InteractionStateFactory.build(
        PopupReady,
        this.context,
        this
      ) as PopupState;
    }

    return this._popup;
  }

  get pullup(): PullupState {
    if (isUndefined(this._pullup)) {
      this._pullup = InteractionStateFactory.build(
        PullupReady,
        this.context,
        this
      ) as PullupState;
    }

    return this._pullup;
  }

  get iOfferBanner(): iOfferState {
    if (isUndefined(this._iOfferBanner)) {
      this._iOfferBanner = InteractionStateFactory.build(
        BannerReady,
        this.context,
        this
      ) as iOfferState;
    }

    return this._iOfferBanner;
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  acceptChat(): void {
    this.chat.accept();
  }

  askChatPermission(engagement: ChatOptions): void {
    !this.isInteractionBlocked && this.chat.askPermission(engagement);
  }

  buildState(type: { new (): InteractionState }): InteractionState {
    return InteractionStateFactory.build(type, this.context, this);
  }

  clearChatEngagement(): void {
    this._chatEngagement = undefined;
  }

  clearChatWidget(): void {
    this._chatWidget = undefined;
  }

  clearOfferEngagement(): void {
    this._offerEngagement = undefined;
  }

  close(): void {
    this.dialog.close();
  }

  closeChatNotify(): void {
    this.chat.closeNotify();
  }

  closeTerms(): void {
    this.dialog.closeTerms();
  }

  continueChat(engagament: ChatOptions): void {
    this.chat.continue(engagament);
  }

  denyChat(): void {
    this.chat.deny();
  }

  destroyChat(): void {
    this.chat.destroy();
  }

  formatPhone(element: any): void {
    this.dialog.formatPhone(element);
  }

  handleVehicleInputModal(element: any): void {
    this.iOfferBanner.handleVehicleInputModal(element);
  }

  handleVinInputModal(element: any): void {
    this.iOfferBanner.handleVinInputModal(element);
  }

  handlePlateInputModal(element: any): void {
    this.iOfferBanner.handlePlateInputModal(element);
  }

  handleSelectPlateModal(element: any): void {
    this.iOfferBanner.handleSelectPlateModal(element);
  }

  handleVehicleWritingModal(element: any): void {
    this.iOfferBanner.handleVehicleWritingModal(element);
  }

  joinChat(engagament: ChatOptions): Promise<void> {
    return this.chat.join(engagament);
  }

  openPullup(offer: Offer): void {
    this.pullup.openPullup(offer);
  }

  openTerms(): void {
    this.dialog.openTerms();
  }

  openVehicleTabModal(offer?: Offer): void {
    this.iOfferBanner.openVehicleTabModal(offer ?? undefined);
  }

  openVinTabModal(offer?: Offer): void {
    this.iOfferBanner.openVinTabModal(offer ?? undefined);
  }

  openPlateTabModal(offer?: Offer): void {
    this.iOfferBanner.openPlateTabModal(offer ?? undefined);
  }

  closeModal(): void {
    this.iOfferBanner.closeModal();
  }

  selectItemModal(e: any): void {
    this.iOfferBanner.selectItemModal(e);
  }

  sendChatMessage(message: string): Promise<void> {
    return this.chat.sendMessage(message);
  }

  setChatEngagement(update: ChatOptions): void {
    this._chatEngagement = update;
  }

  setChatState(update: ChatState): void {
    this._chat = update;
  }

  setChatWidget(update: ChatWidget): void {
    this._chatWidget = update;
  }

  setContext(update: InteractionManager): void {
    this._context = update;
  }

  setDialogState(update: DialogState): void {
    this._dialog = update;
  }

  setOfferEngagement(update: OfferEngagement): void {
    this._offerEngagement = update;
  }

  setOfferState(update: OfferState): void {
    this._offer = update;
  }

  setPopupState(update: PopupState): void {
    this._popup = update;
  }

  setPullupState(update: PullupState): void {
    this._pullup = update;
  }

  showOffer(engagement: OfferEngagement): void {
    !this.isInteractionBlocked && this.offer.showOffer(engagement);
  }

  showPopup(offer: Offer): void {
    !this.isInteractionBlocked && this.popup.showPopup(offer);
  }

  showPullup(offer: Offer): void {
    !this.isInteractionBlocked && this.pullup.showPullup(offer);
  }

  showBanner(): void {
    !this.isInteractionBlocked && this.iOfferBanner.showBanner();
  }

  showScheduled(offer: Offer): void {
    if (this.isInteractionBlocked) {
      return;
    }

    if (offer.isPopUpOffer) {
      this.showPopup(offer);
      // show it here till it needs its own property
      // this.showBanner();
    }

    if (offer.isPullupOffer) {
      this.showPullup(offer);
    }

    if (offer.isBlendedOffer) {
      const pullupContainer = document.getElementsByClassName(
        "pi-pullup-container"
      );
      const pullupContainerDcomDP = document.querySelectorAll(
        'div[data-location="vehicle-payments"]'
      );
      const pullupContainerDcomSP = document.querySelectorAll(
        'div[data-location="vehicle-payments-primary"]'
      );
      const pullupContainerDcomPB = document.querySelectorAll(
        'div[data-location="primary-banner"]'
      );
      const shown = this.storage.getShownOffersCount() ?? 0;
      const pullupShown = this.state.pullupShown;

      if (pullupShown == undefined) {
        (pullupContainer?.length > 0 ||
          pullupContainerDcomDP?.length > 0 ||
          pullupContainerDcomSP?.length > 0 ||
          pullupContainerDcomPB?.length > 0) &&
          this.showPullup(offer);
      } else if (pullupShown === true && shown < 1) {
        this.showPopup(offer);
        (pullupContainer?.length > 0 ||
          pullupContainerDcomDP?.length > 0 ||
          pullupContainerDcomSP?.length > 0 ||
          pullupContainerDcomPB?.length > 0) &&
          this.showPullup(offer);
      } else {
        (pullupContainer?.length > 0 ||
          pullupContainerDcomDP?.length > 0 ||
          pullupContainerDcomSP?.length > 0 ||
          pullupContainerDcomPB?.length > 0) &&
          this.showPullup(offer);
      }
    }
  }

  submitForm(): void {
    this.dialog.submit();
  }

  clickTerms(event: any): void {
    this.dialog.clickTerms(event);
  }

  submitMultiOneForm(offer?: Offer): void {
    this.dialog.submitMultiOneForm(offer);
  }

  switchChatState(update: { new (): ChatState }): void {
    const state = this.buildState(update) as ChatState;
    this.setChatState(state);
  }

  switchDialogState(update: { new (): DialogState }): void {
    const state = this.buildState(update) as DialogState;
    this.setDialogState(state);
  }

  switchMachine(type: { new (): InteractionMachine }): void {
    this.context.switchMachine(type);
  }

  switchOfferState(update: { new (): OfferState }): void {
    const state = this.buildState(update) as OfferState;
    this.setOfferState(state);
  }

  switchPopupState(update: { new (): PopupState }): void {
    const state = this.buildState(update) as PopupState;
    this.setPopupState(state);
  }

  switchPullupState(update: { new (): PullupState }): void {
    const state = this.buildState(update) as PullupState;
    this.setPullupState(state);
  }
}
