import { isString, isUndefined } from "../../../../../service/lang";

import AppConfig from "../../../app.config";
import BasePopupBuilder from "../base/base_popup_builder";
import BasePullupBuilder from "../base/base_pullup_builder";
import Factory from "../../../../../service/factory";
import Offer from "../../../model/offer";
import OfferEngagement from "../../../model/offer_engagement";
import PopupBuilder from "../popup_builder";
import PullupBuilder from "../pullup_builder";
import PrimaryBannerBuilder from "../primary_banner_builder";
import BasePrimaryBannerBuilder from "../base/base_primary_banner_builder";

export default class OfferComposer {
  private _popupBuilder: PopupBuilder | undefined;
  private _pullupBuilder: PullupBuilder | undefined;
  private _primaryBannerBuilder: PrimaryBannerBuilder | undefined;

  protected get popupBuilder(): PopupBuilder {
    if (isUndefined(this._popupBuilder)) {
      this._popupBuilder = Factory.instance.build(BasePopupBuilder);
    }

    return this._popupBuilder;
  }

  protected get pullupBuilder(): PullupBuilder {
    if (isUndefined(this._pullupBuilder)) {
      this._pullupBuilder = Factory.instance.build(BasePullupBuilder);
    }

    return this._pullupBuilder;
  }

  protected get primaryBannerBuilder(): PrimaryBannerBuilder {
    if (isUndefined(this._primaryBannerBuilder)) {
      this._primaryBannerBuilder = Factory.instance.build(
        BasePrimaryBannerBuilder
      );
    }

    return this._primaryBannerBuilder;
  }

  composeEngagementOffer(engagement: OfferEngagement): string {
    this.popupBuilder.reset();

    if (isString(engagement.source)) {
      this.popupBuilder.setTemplate(engagement.source);
    }

    if (isString(engagement.style)) {
      this.popupBuilder.setStyle(engagement.style);
    }

    this.popupBuilder.setTermsAndConditions(
      AppConfig.instance.locale.offerTermsAndCons
    );

    return this.popupBuilder.build();
  }

  composePopupOffer(offer: Offer): string {
    const image = offer.asset?.objectId ?? offer.template.assetObjectId ?? "";
    const name = "";
    const template = offer?.template?.multiphaseEnabled
      ? offer?.template?.body
          ?.replace(
            /{{main_color}}/g,
            `${offer?.template?.multiphaseMainColor}`
          )
          .replace(
            /{{border_radius_multi}}/g,
            String(offer?.template?.multiphaseTheme) ===
              String("MULTIPHASE_SHARP_EDGE")
              ? "0"
              : "16px"
          )
          .replace(
            /SUBMIT/g,
            String(offer?.template?.multiphaseTheme) ===
              String("MULTIPHASE_SHARP_EDGE")
              ? "Submit"
              : "SUBMIT"
          )
          .replace(
            /{{background_color}}/g,
            `${offer?.template?.multiphaseBackgroundColor}`
          ) ?? ""
      : offer.template.body ?? "";

    const style = offer.template.style ?? "";
    const termsAndConditions =
      offer.termsAndConditions ?? AppConfig.instance.locale.offerTermsAndCons;

    this.popupBuilder.reset();
    this.popupBuilder.setTemplate(template);
    this.popupBuilder.setImage(image);
    this.popupBuilder.setName(name);
    this.popupBuilder.setTermsAndConditions(termsAndConditions);
    this.popupBuilder.setStyle(style);

    return this.popupBuilder.build();
  }

  composePullupOffer(offer: Offer): string {
    const source: string = offer.pullupOfferSource ?? "";
    const offerId: string = offer.externalRefId ?? "";

    this.pullupBuilder.reset();
    this.pullupBuilder.setTemplate(source);
    this.pullupBuilder.setOfferId(offerId);

    return this.pullupBuilder.build();
  }

  composePrimaryBanner(offer: Offer): string {
    const source: string = offer.pullupOfferSource ?? "";
    const offerId: string = offer.externalRefId ?? "";
    const style =
      "._pi_rts_primary_banner_item {\n    padding-bottom: 24px !important;\n    padding-top: 8px !important;\n    width: 100%;\n    background-color: red !important;\n    color: white !important;\n    font-size: 2rem;\n}";

    this.primaryBannerBuilder.reset();
    this.primaryBannerBuilder.setTemplate(source);
    this.primaryBannerBuilder.setOfferId(offerId);
    this.primaryBannerBuilder.setStyle(style);

    return this.primaryBannerBuilder.build();
  }

  setPopupBuilder(update: PopupBuilder): void {
    this._popupBuilder = update;
  }
}
