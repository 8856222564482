import { isObject, parseString } from "../../../../service/lang";
import MultiphaseTheme from "../../enum/multiphase_theme";

import Template from "../template";

export class BaseTemplate implements Template {
  private _json: any;

  constructor(json: any) {
    if (isObject(json)) {
      this._json = json;
    }
  }

  get assetObjectId(): string | undefined {
    return parseString(this._json?.[BaseTemplateKeys.assetObjectId]);
  }

  get body(): string | undefined {
    return parseString(this._json?.[BaseTemplateKeys.body]);
  }

  get style(): string | undefined {
    return parseString(this._json?.[BaseTemplateKeys.style]);
  }

  get multiphaseEnabled(): boolean {
    return this._json?.multiphaseEnabled;
  }

  get multiphaseTheme(): MultiphaseTheme {
    return this._json?.[BaseTemplateKeys.multiphaseTheme];
  }

  get multiphaseText(): string | undefined {
    return parseString(this._json?.[BaseTemplateKeys.multiphaseText]);
  }

  get multiphaseMainColor(): string | undefined {
    return parseString(this._json?.[BaseTemplateKeys.multiphaseMainColor]);
  }

  get multiphaseBackgroundColor(): string | undefined {
    return parseString(
      this._json?.[BaseTemplateKeys.multiphaseBackgroundColor]
    );
  }
}

export class BaseTemplateKeys {
  static assetObjectId: string = "assetObjectId";
  static body: string = "body";
  static style: string = "style";
  static multiphaseEnabled: string = "multiphaseEnabled";
  static multiphaseTheme: string = "multiphaseTheme";
  static multiphaseText: string = "multiphaseText";
  static multiphaseMainColor: string = "multiphaseMainColor";
  static multiphaseBackgroundColor: string = "multiphaseBackgroundColor";
}
