import Interactions from "../../manager/interaction/interactions";

export const StyleCSS = `
  <style id="${Interactions.StyleKeys.container}">
    ${Interactions.StyleSelectors.disabled} {
      border: none !important;
      box-shadow:none !important;
      background: #dddddd !important;
      pointer-events: none !important;
    }

    ${Interactions.PopupSelectors.submitButton} {
      position: relative !important;
    }

    ${Interactions.PopupSelectors.submitButtonMultiOne} {
      position: relative !important;
    }

    ${Interactions.StyleSelectors.buttonLoading} .button__text {
      visibility: hidden;
      opacity: 0;
    }
    
    ${Interactions.StyleSelectors.buttonLoading}::after {
      animation: ${Interactions.StyleKeys.buttonLoading}-spinner 1s ease infinite;
      border: 4px solid transparent;
      border-radius: 50%;
      border-top-color: #000;
      bottom: 0;
      content: "";
      height: 16px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 16px;
    }
    
    @keyframes ${Interactions.StyleKeys.buttonLoading}-spinner {
      from {
        transform: rotate(0turn);
      }
      to {
        transform: rotate(1turn);
      }
    }
  </style>
`;
