namespace CSS {
  export class Options {
    static readonly important = "important";
  }

  export class Props {
    static readonly borderColor = "border-color";
    static readonly display = "display";
    static readonly opacity = "opacity";
    static readonly color = "color";
    static readonly borderBottomColor = "border-bottom-color";
  }

  export class Values {
    static readonly block = "block";
    static readonly inlineBlock = "inline-block";
    static readonly none = "none";
    static readonly flex = "flex";
  }
}

export default CSS;
