import { isUndefined } from "../../../../../../../service/lang";
import Offer from "../../../../../model/offer";
import StateSpecification from "../../../state/state_specification";

export default class MultipartIncluded extends StateSpecification {
  /**
   * @override
   */
  isSatisfiedBy(source: Offer): boolean {
    const isSatisfied: boolean = source?.template?.multiphaseEnabled === true;
    return isSatisfied;
  }
}
