import BaseDOMService from "../../../service/dom/base/base_dom_service";
import BaseDeviceService from "../../../service/device/base/base_device_service";
import BaseOptionsService from "../../../service/options/base/base_options_service";
import BaseRTSService from "../../../service/rts/base/base_rts_service";
import BaseStorageRegistrar from "../../../service/storage/base/base_storage_registrar";
import ChatModes from "../../../enum/chat_modes";
import DOMEvents from "../../../../../service/dom/dom_events";
import DOMService from "../../../service/dom/dom_service";
import DeviceService from "../../../service/device/device_service";
import Factory from "../../../../../service/factory";
import OptionsService from "../../../service/options/options_service";
import RTSDataComposer from "../../../service/rts/rts_data_composer";
import RTSResponse from "../../../service/rts/rts_response";
import RTSService from "../../../service/rts/rts_service";
import State from "../../../state/state";
import StateService from "../state_service";
import StorageRegistrar from "../../../service/storage/storage_registrar";
import { attachWindowListener } from "../../../../../service/dom/dom_utilities";
import { isUndefined } from "../../../../../service/lang";

export default class BaseStateService implements StateService {
  private _device: DeviceService | undefined;
  private _dom: DOMService | undefined;
  private _options: OptionsService | undefined;
  private _rts: RTSService | undefined;
  private _storage: StorageRegistrar | undefined;
  protected _composer: RTSDataComposer | undefined;

  protected get composer(): RTSDataComposer {
    if (isUndefined(this._composer)) {
      this._composer = Factory.instance.build(RTSDataComposer);
    }

    return this._composer;
  }

  protected get device(): DeviceService {
    if (isUndefined(this._device)) {
      this._device = Factory.instance.build(BaseDeviceService);
    }

    return this._device;
  }

  protected get dom(): DOMService {
    if (isUndefined(this._dom)) {
      this._dom = Factory.instance.build(BaseDOMService);
    }

    return this._dom;
  }

  get isVisitorConverted(): boolean {
    return this.storage.getVisitorConverted() === true;
  }

  get getIOfferFlowCompleted(): boolean {
    return this.storage.getIOfferFlowCompleted() === true;
  }

  protected get options(): OptionsService {
    if (isUndefined(this._options)) {
      this._options = Factory.instance.build(BaseOptionsService);
    }

    return this._options;
  }

  protected get rts(): RTSService {
    if (isUndefined(this._rts)) {
      this._rts = Factory.instance.build(BaseRTSService);
    }

    return this._rts;
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  attachScrollListener(callback: Function): void {
    attachWindowListener(DOMEvents.scroll, callback);
  }

  composeInitialData(state: State): any {
    return this.composer.composeInitial(state);
  }

  composeStandardData(state: State): any {
    return this.composer.composeStandard(state);
  }

  getScrollDepth(): number {
    return this.dom.scrollDepth;
  }

  hasReachedPersistentChatThreshold(visitorScore: number): boolean {
    return (
      this.options.chatMode !== ChatModes.disabled &&
      this.options.hasPersistentChat &&
      visitorScore >= this.options.persistentChatThreshold
    );
  }

  isVisitorActive(): boolean {
    return this.dom.isActive;
  }

  isVisitorBot(): boolean {
    return this.device.isBot();
  }

  sendRTSData(data: any, initial?: boolean): Promise<RTSResponse> {
    return initial ? this.rts.sendData(data, initial) : this.rts.sendData(data);
  }

  setVisitorConverted(): void {
    this.storage.setVisitorConverted();
  }
}
