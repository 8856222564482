enum StorageKeys {
  blockedSent = "_pi_rts_bs",
  campaign = "_pi_rts_c",
  chatNetworkError = "_pi_rts_cne",
  chatSession = "_pi_rts_cs",
  deactivated = "_pi_rts_dd",
  dealershipId = "_pi_rts_d",
  deviceType = "_pi_rts_dt",
  everVisitedOffer = "_pi_rts_evo",
  fingerprint = "_pi_rts_f",
  geolocation = "_pi_rts_g",
  isInteractionBlocked = "_pi_rts_bi",
  loggerEnabled = "_pi_rts_le",
  masterConifg = "_pi_rts_mc",
  pendingChat = "_pi_rts_pc",
  pendingOffer = "_pi_rts_po",
  persistentChatShownSent = "_pi_rts_pcss",
  persistentChatWidgetState = "_pi_rts_pcws",
  pullupSession = "_pi_rts_ps",
  sessionId = "_pi_rts_s",
  shownOffersCount = "_pi_rts_soc",
  test = "_pi_rts_t",
  uniquePageViews = "_pi_rts_upv",
  visitorConverted = "_pi_rts_vc",
  visitorId = "_pi_rts_v",
  visitorStatus = "_pi_rts_vs",
  dealershipTs = "_pi_rts_dts",
  visitorInfo = "_pi_rts_vi",
  iOfferFlowCompleted = "_pi_rts_iOffer_c",
}

namespace StorageKeys {
  export enum Old {
    visitorConverted = "_pi_rts_rsl",
    visitorId = "_pi_rts_vid",
  }
}

export default StorageKeys;
