import Offer from "../../../../../model/offer";
import InteractionState from "../interaction_state";

export interface DialogState extends InteractionState {
  close(): void;

  closeTerms(): void;

  formatPhone(element: any): void;

  openTerms(): void;

  submit(): Promise<void>;

  clickTerms(event: any): void;

  submitMultiOneForm(offer?: Offer): void;
}
