import { iOfferBanner } from "./iOffer_banner";
import { iOfferState } from "./iOffer_state";

export class BannerReady extends iOfferBanner implements iOfferState {
  /**
   * @override
   */
  showBanner(): void {
    super.showBanner();
  }
}
