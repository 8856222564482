import {
  ChatReady,
  ChatSession,
  InteractionMachine,
  MachineBlocked,
  MachinePaused,
  MachineReady,
  MachineTest,
  PopupSuspended,
  PullupSuspended,
} from "../machines";
import {
  isNull,
  isNumber,
  isString,
  isUndefined,
} from "../../../../../service/lang";

import BaseInteractionService from "./base_interaction_service";
import BaseLogger from "../../../../../service/logger/base/base_logger";
import BaseNotifier from "../../../service/notifier/base/base_notifier";
import BaseObservable from "../../../../../service/observer/base/base_observable";
import { BaseOfferEngagement } from "../../../model/base/base_offer_engagement";
import ChatEngagementOptionsAdapter from "../machines/states/chat/adapter/chat_engagement_options_adapter";
import { ChatOptions } from "../machines/states/chat/typedef";
import DOMEvents from "../../../../../service/dom/dom_events";
import EngagementTypes from "../../../enum/engagement_types";
import Events from "../../../enum/events";
import Factory from "../../../../../service/factory";
import InteractionMachineFactory from "../machines/interaction_machine_factory";
import InteractionManager from "../interaction_manager";
import InteractionService from "../interaction_service";
import Interactions from "../interactions";
import Logger from "../../../../../service/logger/logger";
import Notifier from "../../../service/notifier/notifier";
import Observable from "../../../../../service/observer/observable";
import Offer from "../../../model/offer";
import OfferEngagement from "../../../model/offer_engagement";
import PersistentChatOptionsAdapter from "../machines/states/chat/adapter/persistent_chat_engagement_options_adapter";
import PersistentChatWidget from "../../../../../persistent_chat/src/widget/chat_widget";
import PureinfluencerAdapter from "../../../adapter/pureinfluencer_adapter";
import UnimplementedError from "../../../utilities/errors/unimplemented_error";
import {
  fadeIn,
  fadeOut,
  findById,
} from "../../../../../service/dom/dom_utilities";
import URLService from "../../../service/url/url_service";
import BaseURLService from "../../../service/url/base/base_url_service";

export default class BaseInteractionManager
  extends BaseObservable
  implements InteractionManager
{
  private _currentOffer: Offer | undefined | null = null;
  private _logger: Logger | undefined;
  protected _machine: InteractionMachine | undefined;
  private _notifier: Notifier | undefined;
  private _persistentChat: PersistentChatWidget | undefined;
  private _scheduleHandle: any;
  private _service: InteractionService | undefined;
  private _url: URLService | undefined;

  protected get url(): URLService {
    if (isUndefined(this._url)) {
      this._url = Factory.instance.build(BaseURLService);
    }

    return this._url;
  }

  get currentOffer(): Offer | undefined {
    if (isNull(this._currentOffer)) {
      this._currentOffer = this.pickOffer();
    }

    return this._currentOffer;
  }

  get hasPendingChat(): boolean {
    return this.service.hasPendingChat;
  }

  get hasPendingOffer(): boolean {
    return this.service.hasPendingOffer;
  }

  get isDealershipPaused(): boolean {
    return this.service.isDealershipPaused;
  }

  get isPopupTest(): boolean {
    return this.service.isPopupTest;
  }

  get isMultiTest(): boolean {
    return this.service.isMultiTest;
  }

  get isSpecificPopupTest(): boolean {
    return this.service.isSpecificPopupTest;
  }

  get isPullupSession(): boolean {
    return this.service.hasPullupSession;
  }

  get isPullupTest(): boolean {
    return this.service.isPullupTest;
  }

  get isVisitorBlocked(): boolean {
    return this.service.isVisitorBlocked;
  }

  get isVisitorConverted(): boolean {
    return this.service.isVisitorConverted;
  }

  get getIOfferFlowCompleted(): boolean {
    return this.service.getIOfferFlowCompleted;
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  get machine(): InteractionMachine {
    if (isUndefined(this._machine)) {
      this._machine = this.buildMachine(MachineReady);
      this._machine.setContext(this);
    }

    return this._machine;
  }

  get notifier(): Notifier {
    if (isUndefined(this._notifier)) {
      this._notifier = Factory.instance.build(BaseNotifier);
    }

    return this._notifier;
  }

  protected get persistentChat(): Promise<PersistentChatWidget> {
    return new Promise((resolve, reject) => {
      if (isUndefined(this._persistentChat)) {
        this.logger.info("Initiating Persistent Chat...");
        const callback = this.onPersistentChatMessage.bind(this);
        this.service
          .getPersistentChatWidget(callback)
          .then((widget) => {
            this._persistentChat = widget;
            resolve(widget);
            this.logger.info("Persistent Chat initiated!");
          })
          .catch((error) => reject(error));
      } else {
        resolve(this._persistentChat);
      }
    });
  }

  protected get service(): InteractionService {
    if (isUndefined(this._service)) {
      this._service = Factory.instance.build(BaseInteractionService);
    }

    return this._service;
  }

  protected get shouldShowOnLeave(): boolean {
    return this.currentOffer?.showOnLeave === true;
  }

  acceptChat(): void {
    this.service
      .hidePersistentChat(this.persistentChat)
      .catch((error) => this.logger.warning(error));
    this.machine.acceptChat();
  }

  buildMachine(type: { new (): InteractionMachine }): InteractionMachine {
    return InteractionMachineFactory.build(type, this, this._machine);
  }

  protected clearCurrentOffer(): void {
    this._currentOffer = null;
  }

  protected clearMachine(): void {
    this._machine = undefined;
  }

  closeChatNotify(): void {
    this.machine.closeChatNotify();
  }

  closePopup(): void {
    this.machine.close();
  }

  closeTerms(): void {
    this.machine.closeTerms();
  }

  continueChat(): void {
    const engagement = this.service.getChatSession();
    this.machine.continueChat(engagement);
  }

  denyChat(): void {
    this.machine.denyChat();
  }

  endChat(): void {
    this.machine.destroyChat();
    this.machine.switchChatState(ChatReady);
    this.machine.clearChatEngagement();
    this.machine.clearChatWidget();
    this.service.clearChatSession();
    this.service
      .initializePersistentChatWidget(this.persistentChat)
      .catch((error) => this.logger.warning(error));
  }

  getInteractionTimeout(offer: Offer): number | undefined {
    return this.service.getOfferTimeout(offer);
  }

  getOfferById(id: string): Offer | undefined {
    return this.service.getOfferById(id);
  }

  getPullupOfferId(event: any): string | undefined {
    return this.service.getPullupOfferId(event);
  }

  getPrimaryBannerOfferId(event: any): string | undefined {
    return this.service.getPrimaryBannerOfferId(event);
  }

  handleChatSession(): void {
    this.machine.switchChatState(ChatSession);
    this.continueChat();
  }

  handleEngagement(data: any): void {
    const engagementType = PureinfluencerAdapter.engagementType(
      data?.engagementType
    );
    this.notifier.acknowledgeEngagement();

    switch (engagementType) {
      case EngagementTypes.chat:
        this.handleEngagementChat(new ChatEngagementOptionsAdapter(data));
        break;
      case EngagementTypes.offer:
        this.handleEngagementOffer(new BaseOfferEngagement(data));
        break;
      default:
        throw new UnimplementedError(data?.engagementType);
    }
  }

  handleEngagementChat(engagement: ChatOptions): void {
    this.logger.info("Handling chat engagement:", engagement);
    this.notifier.notifyDeliveredChat(engagement.historyId);
    this.machine.askChatPermission(engagement);
  }

  handleEngagementOffer(engagement: OfferEngagement): void {
    this.logger.info("Handling offer engagement:", engagement);
    this.notifier.notifyDeliveredOffer(engagement.historyId);
    this.machine.showOffer(engagement);
  }

  handlePausedDealership(): void {
    this.logger.warning("Dealership is paused!");
    this.switchMachine(MachinePaused);
  }

  handlePendingChat(): void {
    const engagament = this.service.getPendingChat();
    this.machine.askChatPermission(engagament);
    this.service.clearPendingChat();
  }

  handlePendingOffer(): void {
    const engagement = this.service.getPendingOffer();
    this.machine.showOffer(engagement);
    this.service.clearPendingOffer();
  }

  protected handlePersistentChatThreshold(): void {
    if (!this.service.isChatSession) {
      this.service.initializePersistentChatWidget(this.persistentChat);
      this.service.notifyPersistentChatShown();
    }
  }

  handlePhoneInput(target: any): void {
    this.machine.formatPhone(target);
  }

  handleVehicleInputModal(target: any): void {
    this.machine.handleVehicleInputModal(target);
  }

  handleVinInputModal(target: any): void {
    this.machine.handleVinInputModal(target);
  }

  handlePlateInputModal(target: any): void {
    this.machine.handlePlateInputModal(target);
  }

  handleSelectPlateModal(target: any): void {
    this.machine.handleSelectPlateModal(target);
  }

  handleVehicleWritingModal(target: any): void {
    this.machine.handleVehicleWritingModal(target);
  }

  handlePopupTest(): void {
    this._currentOffer = this.pickTestOffer();

    if (!isUndefined(this.currentOffer)) {
      this.switchMachine(MachineTest);
      this.showPopup(this.currentOffer);
    }
  }

  handleMultiTest(offerId?: string): void {
    this._currentOffer = this.pickMultiTestOffer(offerId ?? "");
    // get the right offer by offerId
    if (!isUndefined(this.currentOffer)) {
      this.switchMachine(MachineTest);
      this.showPopup(this.currentOffer);
      fadeOut(findById(Interactions.PopupSelectors.multiphaseOne));
      fadeIn(findById(Interactions.PopupSelectors.multiphaseTwo));
    }
  }

  handleSpecificPopupTest(): void {
    this._currentOffer = this.pickSpecificTestOffer();

    if (!isUndefined(this.currentOffer)) {
      this.switchMachine(MachineTest);
      this.showPopup(this.currentOffer);
    }
  }

  handlePullupSession(): void {
    if (!isUndefined(this.currentOffer)) {
      this.showPullup(this.currentOffer);
    }

    this.scheduleAutomaticOffer();
  }

  handlePullupTest(): void {
    this._currentOffer = this.pickTestOffer();

    if (!isUndefined(this.currentOffer)) {
      this.switchMachine(MachineTest);
      this.showPullup(this.currentOffer);
    }
  }

  handleTabLeave(): void {
    if (!isUndefined(this.currentOffer)) {
      if (this.shouldShowOnLeave) {
        this.unscheduleAutomaticOffer();
        this.showScheduled();
      }
    }
  }

  handleVisitorBlocked(): void {
    this.logger.warning("Visitor IP is blocked!");
    this.switchMachine(MachineBlocked);
  }

  handleVisitorConverted(): void {
    this.logger.warning(
      "Visitor has been converted, popups and pullups are suspended!"
    );
    this.machine.switchPopupState(PopupSuspended);
    this.machine.switchPullupState(PullupSuspended);
  }

  initialize(): Promise<void> {
    this.initializeListeners();

    if (this.isSpecificPopupTest) {
      this.handleSpecificPopupTest();
    } else if (this.isPopupTest) {
      this.handlePopupTest();
    } else if (this.isMultiTest) {
      const search = this.url.getCurrentSearchParams() ?? "";
      this.handleMultiTest(search?.offerId);
    } else if (this.isPullupTest) {
      this.handlePullupTest();
    } else if (this.isDealershipPaused) {
      this.handlePausedDealership();
    } else if (this.isVisitorBlocked) {
      this.handleVisitorBlocked();
    } else if (this.service.isChatSession) {
      this.handleChatSession();
    } else if (this.hasPendingOffer) {
      this.handlePendingOffer();
    } else if (this.hasPendingChat) {
      this.handlePendingChat();
    } else if (this.isVisitorConverted) {
      this.handleVisitorConverted();
    } else if (this.isPullupSession) {
      this.handlePullupSession();
    } else {
      this.scheduleAutomaticOffer();
    }

    return Promise.resolve();
  }

  initializeListeners(): void {
    this.logger.info("Initialize Listeners");
    this.service.attachEventListener(
      Events.chatClosed,
      Factory.instance.callback(this.onChatEnded, this)
    );
    this.service.attachDOMEventListener(
      DOMEvents.click,
      Factory.instance.callback(this.onClick, this)
    );
    this.service.attachDOMEventListener(
      DOMEvents.input,
      Factory.instance.callback(this.onInput, this)
    );
    this.service.attachDOMEventListener(
      DOMEvents.mouseLeave,
      Factory.instance.callback(this.onMouseLeave, this)
    );
    this.service.attachDOMEventListener(
      DOMEvents.keyUp,
      Factory.instance.callback(this.onKeyUp, this)
    );
    this.service.attachDOMEventListener(
      DOMEvents.change,
      Factory.instance.callback(this.onChange, this)
    );
  }

  protected onChatEnded(): void {
    this.endChat();
  }

  protected onClick(event: any): void {
    this.logger.info("On Click", event);
    if (Interactions.isSupported(event)) {
      event.preventDefault();
      if (Interactions.isAcceptChat(event)) {
        this.acceptChat();
      } else if (
        Interactions.isClickPullup(event) ||
        Interactions.isClickPrimaryBanner(event)
      ) {
        this.openPullup(event);
      } else if (Interactions.isClosePopup(event)) {
        const isMultiphaseSecondForm = findById(
          Interactions.PopupSelectors.multiphaseTwo
        );
        isMultiphaseSecondForm?.style?.display === "block" && this.submitForm();
        this.closePopup();
      } else if (Interactions.isCloseTermsAndConditions(event)) {
        this.closeTerms();
      } else if (Interactions.isDenyChat(event)) {
        this.denyChat();
      } else if (Interactions.isNotifyCloseButton(event)) {
        this.closeChatNotify();
      } else if (Interactions.isOpenTermsAndConditions(event)) {
        this.openTerms();
      } else if (Interactions.isMultiOneForm(event)) {
        this.submitMultiOneForm(this.currentOffer);
      } else if (Interactions.isSubmitForm(event)) {
        this.submitForm();
      } else if (Interactions.isTermsClicked(event)) {
        this.clickTerms(event);
      } else if (Interactions.isClickVehicleTabModal(event)) {
        this.openVehicleTabModal(this.currentOffer);
      } else if (Interactions.isClickVinTabModal(event)) {
        this.openVinTabModal(this.currentOffer);
      } else if (Interactions.isClickPlateTabModal(event)) {
        this.openPlateTabModal(this.currentOffer);
      } else if (Interactions.isClickCloseX(event)) {
        this.closeModal();
        if (this.getIOfferFlowCompleted) {
          this.submitForm();
          this.closePopup();
        }
      } else if (Interactions.isClickVehicleItemModal(event)) {
        this.selectItemModal(event);
      }
    }
  }

  protected onInput(event: any): void {
    if (Interactions.isPhoneInput(event)) {
      event.preventDefault();
      this.handlePhoneInput(event.target);
    } else if (Interactions.isSearchVehicleInputModal(event)) {
      event.preventDefault();
      this.handleVehicleInputModal(event.target);
    }
  }

  protected onKeyUp(event: any): void {
    if (Interactions.isSearchVinInputModal(event)) {
      event.preventDefault();
      this.handleVinInputModal(event);
    } else if (Interactions.isSearchPlateInputModal(event)) {
      event.preventDefault();
      this.handlePlateInputModal(event);
    } else if (Interactions.isSearchVehicleInputModal(event)) {
      event.preventDefault();
      this.handleVehicleWritingModal(event);
    }
  }

  protected onChange(event: any): void {
    if (Interactions.isSelectPlateInputModal(event)) {
      event.preventDefault();
      this.handleSelectPlateModal(event);
    }
  }

  protected onMouseLeave(_event: any): void {
    this.handleTabLeave();
  }

  protected onPersistentChatMessage(message: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.service
        .sendPersistentChatMessage(message)
        .then((response) => {
          this.service.hidePersistentChat(this.persistentChat);
          return this.machine.joinChat(
            new PersistentChatOptionsAdapter(response)
          );
        })
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  }

  openPullup(event: any): void {
    const id = this.getPullupOfferId(event);
    if (!isUndefined(id)) {
      if (isString(id)) {
        const offer = this.getOfferById(id);

        if (!isUndefined(offer)) {
          this.machine.openPullup(offer);
        }
      }
    } else {
      const id = this.getPrimaryBannerOfferId(event);
      if (isString(id)) {
        const offer = this.getOfferById(id);

        if (!isUndefined(offer)) {
          this.machine.openPullup(offer);
        }
      }
    }
  }

  openTerms(): void {
    this.machine.openTerms();
  }

  openVehicleTabModal(offer?: Offer): void {
    this.machine.openVehicleTabModal(offer ?? undefined);
  }

  openVinTabModal(offer?: Offer): void {
    this.machine.openVinTabModal(offer ?? undefined);
  }

  openPlateTabModal(offer?: Offer): void {
    this.machine.openPlateTabModal(offer ?? undefined);
  }

  closeModal(): void {
    this.machine.closeModal();
  }

  selectItemModal(e: any): void {
    this.machine.selectItemModal(e);
  }

  pickOffer(): Offer | undefined {
    return this.service.pickOffer();
  }

  pickTestOffer(): Offer | undefined {
    return this.service.pickTestOffer();
  }

  pickMultiTestOffer(offerId: string): Offer | undefined {
    return this.service.pickMultiTestOffer(offerId);
  }

  pickSpecificTestOffer(): Offer | undefined {
    return this.service.pickSpecificTestOffer();
  }

  schedule(callback: Function, timeout: number): number {
    return this.service.schedule(callback, timeout);
  }

  scheduleAutomaticOffer(): void {
    if (!isUndefined(this.currentOffer)) {
      const timeout = this.getInteractionTimeout(this.currentOffer);

      if (isNumber(timeout)) {
        this.unscheduleAutomaticOffer();
        this._scheduleHandle = this.schedule(
          this.showScheduled.bind(this),
          timeout
        );
      }
    }
  }

  setMachine(update: InteractionMachine): void {
    this._machine = update;
  }

  setService(update: InteractionService): void {
    this._service = update;
  }

  showPopup(offer: Offer): void {
    this.machine.showPopup(offer);
  }

  showPullup(offer: Offer): void {
    this.machine.showPullup(offer);
  }

  showScheduled(): void {
    if (!isUndefined(this.currentOffer) && !this.isVisitorConverted) {
      this.machine.showScheduled(this.currentOffer);
    }
  }

  submitForm(): void {
    this.machine.submitForm();
  }

  clickTerms(event: any): void {
    this.machine.clickTerms(event);
  }

  submitMultiOneForm(offer?: Offer): void {
    this.machine.submitMultiOneForm(offer);
  }

  switchMachine(type: { new (): InteractionMachine }): void {
    const update = this.buildMachine(type);
    this.setMachine(update);
  }

  unschedule(handle: number): void {
    this.service.unschedule(handle);
  }

  unscheduleAutomaticOffer(): void {
    this.unschedule(this._scheduleHandle);
  }

  update(_observable: Observable, event: Events, data: any): void {
    switch (event) {
      case Events.engagementReceived:
        this.handleEngagement(data);
        break;
      case Events.persistentChatThresholdReached:
        this.handlePersistentChatThreshold();
        break;
      default:
        throw new UnimplementedError(event);
    }
  }
}
