import { BaseInteractionState, PopupState } from "../../index";

import Offer from "../../../../../model/offer";
import {
  findById,
  setContent,
} from "../../../../../../../service/dom/dom_utilities";
import Interactions from "../../../interactions";
import AppConfig from "../../../../../app.config";

export abstract class BasePopup
  extends BaseInteractionState
  implements PopupState
{
  showPopup(offer: Offer): void {
    const content = this.service.composePopup(offer);
    this.service.pop(content);
    const termsAndConditions =
      offer.termsAndConditions ?? AppConfig.instance.locale.offerTermsAndCons;

    if (offer?.template?.multiphaseEnabled) {
      String(offer?.template?.multiphaseTheme) ===
      String("MULTIPHASE_SHARP_EDGE")
        ? setContent(
            findById(Interactions.iOfferKeys.termsText),
            `
				${offer?.template?.multiphaseText ?? ""}
            <a href="" class="pi_pop_up_terms_and_conditions_link pi_pop_up_terms_and_conditions_link-generated-by-offer">Terms&nbsp;&amp;&nbsp;Conditions</a>
            <div class="pi_pop_up_terms_and_conditions_text pi_pop_up_terms_and_conditions_text-generated-by-offer" hidden="">
                <button type="button" class="pi_pop_up_terms_and_conditions_close_button pi_pop_up_terms_and_conditions_close_button-generated-by-offer">×</button>
                <div class="pi_terms_and_conditions_replace pi_terms_and_conditions_replace-generated-by-offer">${termsAndConditions}</div>
            </div>`
          )
        : setContent(
            findById(Interactions.iOfferKeys.termsText),
            offer?.template?.multiphaseText ?? ""
          );
    }
  }
}
