import Interactions from "../manager/interaction/interactions";

export const iOfferFormPage1 = `
<style>
  body {
    background: #fff !important;
  }
</style>
<div id="${Interactions.iOfferKeys.iOfferFormDiv}">
  <div class="banner-box">
    <div class="banner-box-shadow" style="padding: 20px;">
      <div class="mb-3" style="color: #000; font-size: 25px; text-align: left; font-family: Montserrat Light;">Seller’s Information</div>
      
    </div>
  </div> 
</div>`;

export const multiPhaseOffer = `
<div style="background: rgba(45, 54, 62, 0.7); display: none; height: 100vh; left: 0; position: fixed; top: 0; width: 100vw; z-index: 2000;"
  id="ioffer-backdrop" class="ioffer-backdrop">
  <div style="background: #e8e8e8; left: 50%; position: absolute; top: 50%; transform: translate(-50%, -50%); width: 100%; max-width: 769px; height: 90%;"
      id="ioffer-modal">
      <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cGF0aCBkPSJNNDQzLjYsMzg3LjFMMzEyLjQsMjU1LjRsMTMxLjUtMTMwYzUuNC01LjQsNS40LTE0LjIsMC0xOS42bC0zNy40LTM3LjZjLTIuNi0yLjYtNi4xLTQtOS44LTRjLTMuNywwLTcuMiwxLjUtOS44LDQgIEwyNTYsMTk3LjhMMTI0LjksNjguM2MtMi42LTIuNi02LjEtNC05LjgtNGMtMy43LDAtNy4yLDEuNS05LjgsNEw2OCwxMDUuOWMtNS40LDUuNC01LjQsMTQuMiwwLDE5LjZsMTMxLjUsMTMwTDY4LjQsMzg3LjEgIGMtMi42LDIuNi00LjEsNi4xLTQuMSw5LjhjMCwzLjcsMS40LDcuMiw0LjEsOS44bDM3LjQsMzcuNmMyLjcsMi43LDYuMiw0LjEsOS44LDQuMWMzLjUsMCw3LjEtMS4zLDkuOC00LjFMMjU2LDMxMy4xbDEzMC43LDEzMS4xICBjMi43LDIuNyw2LjIsNC4xLDkuOCw0LjFjMy41LDAsNy4xLTEuMyw5LjgtNC4xbDM3LjQtMzcuNmMyLjYtMi42LDQuMS02LjEsNC4xLTkuOEM0NDcuNywzOTMuMiw0NDYuMiwzODkuNyw0NDMuNiwzODcuMXoiLz48L3N2Zz4="
          alt="Close" id="ioffer-search-close" class="ioffer-search-close"
          style="width: 20px; right: -15px; top: -15px; position: absolute; background: #fff; border-radius: 50%; padding: 5px; box-sizing: content-box;" />
  </div>
</div>`;
