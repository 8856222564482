import { BaseInteractionState, PullupState } from "../../index";

import offer from "../../../../../model/offer";
import {
  createElement,
  insertBodyContent,
} from "../../../../../../../service/dom/dom_utilities";
import State from "../../../../../state/state";
import DOMElements from "../../../../../../../service/dom/dom_elements";
import PageTypes from "../../../../../enum/page_types";

export abstract class BasePullup
  extends BaseInteractionState
  implements PullupState
{
  openPullup(offer: offer): void {
    const content = this.service.composePopup(offer);
    this.service.pop(content);
  }

  protected get state(): State {
    return this.stateBuilder.snapshot;
  }

  showPullup(offer: offer): void {
    const content = this.service.composePullup(offer);
    this.service.pull(content);
    const pullupContainer = document.getElementsByClassName(
      "pi-pullup-container"
    );
    if (
      (this?.state?.pageType == PageTypes.SRP ||
        this?.state?.pageType == PageTypes.VDP) &&
      pullupContainer?.length > 0
    ) {
      const fixedDiv = `<div style="
        position: fixed;
        width: 145px;
        top: 40%;
        transform: rotate(90deg);
        z-index: 2147483647;
        left: -50px;
        " class="_pi_rts_pullup_item left-fixed-div" data-offer-id="${offer?.externalRefId}">
        ${offer?.sideBannerSource}
      </div>`;

      offer?.sideBanner == true && insertBodyContent(fixedDiv);
    }
  }
}
